import React from 'react'
import { setOrderPaid, setOrderInfo } from '../lib/orders_hook'
import { navigate } from 'gatsby'
import { isBrowser } from '../utils/utils'
import Layout from '../components/layout/Layout'
import { Alert } from 'react-bootstrap'

interface OrderPushProps {
  state: string
  orderhash: string
}

const OrderPush: React.FC<OrderPushProps> = ({ state, orderhash }) => {
  let result = false

  if (state == 'success') {
    result = setOrderPaid(orderhash)
  } else if (state == 'failure') {
    result = setOrderInfo(
      orderhash,
      'Fehler bei der Bezahlung. Bitte sende uns eine Mail an service@artboxone.de!',
    )
  }

  if (isBrowser()) {
    if (result) {
      navigate('/orders')
    }
  }

  return (
    <Layout>
      <div className="container">
        {result === false && state === 'success' && (
          <Alert
            key="payment"
            style={{ textAlign: `center` }}
            variant="success"
          >
            Zahlung wird verarbeitet. Weitere Infos gibt es per Mail!
          </Alert>
        )}
        {result === false && state === 'failure' && (
          <Alert key="payment" style={{ textAlign: `center` }} variant="danger">
            Fehler bei der Bezahlung. Bitte sende uns eine Mail an
            service@artboxone.de
          </Alert>
        )}
      </div>
    </Layout>
  )
}

export default OrderPush
